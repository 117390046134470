import React, { Component } from 'react'

export class Header extends Component {
    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg" style={{backgroundColor:"#007aff"}}>
                    <div className="container-fluid">

                        <select className="form-select form-select-lg " aria-label=".form-select-lg example" name='selectCoin'
                            style={{ width: "fit-content" }} onChange={this.props.handle_Submit}>
                            <option value="vaiot">Vaiot (VAI)</option>
                        </select>

                        <a className="navbar-brand d-flex ml-auto display-2 text-dark fs-2 fw-bold text-uppercase "
                            style={{ fontFamily: 'Inter'}} href="/"><img src="https://vaiot.ai/build/website/images/logo_vaiot-white.29f8ffc3.svg"/></a>

                    </div>
                </nav>
            </div>
        )
    }
}

export default Header