import React, { Component } from 'react'

export class CardSection extends Component {
    render() {
        return (
            <div>
                <div className="fs-1 fw-bold m-3 text-Capitalize cardsection"
                    style={{ fontFamily: 'Inter', marginTop: '3px !important', marginBottom: '0px !important' }}>
                    {this.props.coinName}
                </div>
                <div className="fs-1 fw-bold m-3 text-Capitalize cardsection"
                    style={{ fontFamily: 'Inter', marginTop: '3px !important', marginBottom: '0px !important' }}>
                    <a href='https://linktr.ee/vaiot.ai'><img src="./linktree.png" width='200px'/></a>
                </div>
                <section className="row m-3 mb-0 cardsection" style={{ marginTop: ' 2px !important' }}>
                    <div className="card text-white text-center  m-3"
                        style={{ width: "12.5rem", backgroundColor: "hsla(0,0%,100%,.2)", marginTop: "0px !important" }}>
                        <div className="card-body">
                            <h6 className="card-title" style={{ fontFamily: 'Inter' }}>Market Cap 24Hrs</h6>
                            <p className="card-text fw-bold fs-5" style={{ color: "#007aff" }}>
                                {this.props.mCap24} %
                            </p>
                        </div>
                    </div>
                    <div className="card text-white text-center  m-3"
                        style={{ width: "12.5rem", backgroundColor: "hsla(0,0%,100%,.2)", marginTop: "0px !important" }}>
                        <div className="card-body">
                            <h6 className="card-title" style={{ fontFamily: 'Inter' }}>All Time High</h6>
                            <p className="card-text fw-bold fs-5" style={{ color: "#007aff" }}>
                                ${this.props.ath}
                            </p>
                        </div>
                    </div>
                    <div className="card text-white text-center  m-3"
                        style={{ width: "12.5rem", backgroundColor: "hsla(0,0%,100%,.2)", marginTop: "0px !important" }}>
                        <div className="card-body">
                            <h6 className="card-title" style={{ fontFamily: 'Inter' }}>All Time Low</h6>
                            <p className="card-text fw-bold fs-5" style={{ color: "#007aff" }}>
                                ${this.props.atl}
                            </p>
                        </div>
                    </div>

                    
                    <div className="card text-white text-center  m-3"
                        style={{ width: "12.5rem", backgroundColor: "hsla(0,0%,100%,.2)", marginTop: "0px !important" }}>
                        <div className="card-body">
                            <h6 className="card-title" style={{ fontFamily: 'Inter' }}> High 24Hrs </h6>
                            <p className="card-text fw-bold fs-5" style={{ color: "rgb(51, 255, 0) " }}>
                                ${this.props.high24}
                            </p>
                        </div>
                    </div>
                    <div className="card text-white text-center  m-3"
                        style={{ width: "12.5rem", backgroundColor: "hsla(0,0%,100%,.2)", marginTop: "0px !important" }}>
                        <div className="card-body">
                            <h6 className="card-title" style={{ fontFamily: 'Inter' }}> Low 24Hrs </h6>
                            <p className="card-text fw-bold fs-5" style={{ color: 'rgb(255, 32, 32)' }}>
                                ${this.props.low24}
                            </p>
                        </div>
                    </div>
                    <div className="card text-white text-center  m-3"
                        style={{ width: "12.5rem", backgroundColor: "hsla(0,0%,100%,.2)", marginTop: "0px !important" }}>
                        <div className="card-body">
                            <h6 className="card-title" style={{ fontFamily: 'Inter' }}> Holders Count </h6>
                            <p className="card-text fw-bold fs-5" style={{ color: '#007aff' }}>
                                {this.props.houlderscount}
                            </p>
                        </div>
                    </div>

                    <div className="card text-white text-center  m-3"
                        style={{ width: "12.5rem", backgroundColor: "hsla(0,0%,100%,.2)", marginTop: "0px !important" }}>
                        <div className="card-body">
                            <h6 className="card-title" style={{ fontFamily: 'Inter' }}>Positive Sentiments </h6>
                            <p className="card-text fw-bold fs-5" style={{ color: "green" }}>
                                {this.props.sentiment} %
                            </p>
                        </div>
                    </div>

                    <div className="card text-white text-center  m-3"
                        style={{ width: "12.5rem", backgroundColor: "hsla(0,0%,100%,.2)", marginTop: "0px !important" }}>
                        <div className="card-body">
                            <h6 className="card-title" style={{ fontFamily: 'Inter' }}>Negative Sentiments </h6>
                            <p className="card-text fw-bold fs-5" style={{ color: "red" }}>
                                {this.props.sentimentdown} %
                            </p>
                        </div>
                    </div>
                </section>
                <div>
                    <div className="text-white text-center"
                        style={{ fontFamily: 'Inter', overflow: 'visible', height: '2px', marginTop: "1%" }}> Current
                        Price</div>
                    <div style={{
                        fontFamily: 'Inter', fontSize: '70px',
                        fontWeight: '700', color: "#007aff", textDecoration: 'none solid rgb(255, 255, 255)',
                        textAlign: 'center'
                    }}>
                        ${this.props.currentPrice}
                    </div>
                </div>
            </div>
        )
    }
}

export default CardSection